<template>
  <div style="flex: 1;">
    <form ref="dataForm">
      <Modal class="pre-formatted" v-if="showCompleteModal && modalCompleteText"
             v-bind:body="modalCompleteText ? modalCompleteText : ''" v-bind:header="modalCompleteHeader"
             @close="showCompleteModalWindow()"></Modal>
      <component v-bind:is="'P2PDestinationData'" ref="formComponent"
                 v-bind="{formData: formData}"></component>
      <input ref="dataFormSubmit" style="display:none" type="submit">
      <div v-if="error" class="error-label brand-error">
        <span class="c-error-line">{{ $t(error) }}</span>
      </div>
    </form>
    <FormTimer :options="timerOptions" @expired="expired"></FormTimer>
    <div class="panel-footer">
      <div class="panel-footer-submit">
        <ModalP2P v-if="showConfirmModal && modalConfirmText" v-bind:body="modalConfirmText ? modalConfirmText : ''"
                  v-bind:header="modalConfirmHeader" @close="submitForm"></ModalP2P>
        <button class="button-pay button-accent brand-button-accent  brand-button-border-radius"
                type="button"
                v-on:click="showConfirmModalWindow()">
          <template>
            {{ $t('FORM.CONFIRM') }}
          </template>
        </button>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component';
import PayStepBase from '@/components/form/pay/steps/PayStepBase.vue';
import {Ref} from 'vue-property-decorator';
import FormTimer from '@/components/form/pay/FormTimer.vue';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import FormCol from "@/components/form/common/form/layout/FormCol.vue";
import FormContent from "@/components/form/common/form/layout/FormContent.vue";
import TextInputFormField from "@/components/form/common/form/fields/TextInputFormField.vue";
import FormRow from "@/components/form/common/form/layout/FormRow.vue";
import FormField from "@/components/form/common/form/FormField.vue";
import P2PDestinationData from "@/components/form/pay/steps/forms/p2p/P2PDestinationData.vue";
import Modal from "@/components/common/Modal.vue";
import ModalP2P from "@/components/common/ModalP2P.vue";

@Component({
  components: {
    ModalP2P,
    Modal,
    FormField, FormRow, TextInputFormField, FormContent, FormCol,
    FormTimer, P2PDestinationData
  }
})
export default class P2PDestinationInfo extends PayStepBase {
  @Ref('dataForm') readonly dataForm!: HTMLFormElement;
  @Ref('dataFormSubmit') readonly dataFormSubmit!: HTMLElement;
  @Ref('formComponent') readonly formComponent!: IForm;
  isValid = false;
  showConfirmModal = false;
  modalConfirmText = String(this.$t('FORM.CONFIRM_PAYMENT_INFO'));
  modalConfirmHeader = String(this.$t('FORM.CONFIRM_PAYMENT_INFO_TITLE'));
  showCompleteModal = true;
  modalCompleteText = String(this.$t('FORM.COMPLETE_P2P_PAYMENT_INFO'));
  modalCompleteHeader = String(this.$t('FORM.COMPLETE_P2P_PAYMENT_INFO_TITLE'));


  mounted(): void {
    this.startExpirationTimer();
  }

  submitForm() {
    this.dataFormSubmit.click();
  }

  showConfirmModalWindow() {
    this.showConfirmModal = true;
  }

  showCompleteModalWindow() {
    this.showCompleteModal = false;
  }
}
</script>

<style scoped lang="scss">
</style>
