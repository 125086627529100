<template>
  <FormContent>
    <label>
      <b>{{ $t('FORM.DESCRIPTION') }}</b>
    </label>
    <div v-if="formData.p2pDestinationInfo.description">
      <FormRow>
        <FormCol>
          <FormField>
            <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
                 v-bind:class="{'brand-error-container': invalid}">
              <input
                :value="formData.p2pDestinationInfo.description"
                readonly
                class="card-input__input brand-secondary brand-primary-focus"
              />
              <div class="c-info-icon" @click="copy(formData.p2pDestinationInfo.description)">
                <img :src="require(`@/assets/svg/copy.svg`)"/>
              </div>
            </div>
          </FormField>
        </FormCol>
      </FormRow>
    </div>
    <div v-if="formData.p2pDestinationInfo.destination">
      <FormRow>
        <FormCol>
          <FormField>
            <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
                 v-bind:class="{'brand-error-container': invalid}">
              <input
                :value="formData.p2pDestinationInfo.destination"
                readonly
                class="card-input__input brand-secondary brand-primary-focus"
              />
              <div class="c-info-icon" @click="copy(formData.p2pDestinationInfo.destination)">
                <img :src="require(`@/assets/svg/copy.svg`)"/>
              </div>
            </div>
          </FormField>
        </FormCol>
      </FormRow>
    </div>
    <div v-if="formData.p2pDestinationInfo.receiverName">
      <FormRow>
        <FormCol>
          <FormField>
            <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
                 v-bind:class="{'brand-error-container': invalid}">
              <input
                :value="formData.p2pDestinationInfo.receiverName"
                readonly
                class="card-input__input brand-secondary brand-primary-focus"
              />
              <div class="c-info-icon" @click="copy(formData.p2pDestinationInfo.receiverName)">
                <img :src="require(`@/assets/svg/copy.svg`)"/>
              </div>
            </div>
          </FormField>
        </FormCol>
      </FormRow>
    </div>
    <FormRow>
      <FormCol>
        <FormField>
        </FormField>
      </FormCol>
    </FormRow>
    <div v-if="formData.p2pDestinationInfo.amount">
      <FormRow>
        <FormCol>
            <label>
              {{ $t('FORM.AMOUNT_DESCRIPTION.WARNING_MESSAGE') }}
              <img :src="require(`@/assets/svg/exclamation_mark.svg`)"/>
            </label>
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormField>
            <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
                 v-bind:class="{'brand-error-container': invalid}">
              <div class="main-info-body"
                readonly
                v-html="amountFormatted"
              />
              <div class="c-info-icon" @click="copy(amountForCopy)">
                <img :src="require(`@/assets/svg/copy.svg`)"/>
              </div>
            </div>
          </FormField>
        </FormCol>
      </FormRow>
    </div>
    <label>
      {{ $t('FORM.INFO_RETURN_MESSAGE') }}
    </label>
  </FormContent>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import DataFormBase from '@/components/form/pay/steps/forms/DataFormBase';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import Modal from '@/components/common/Modal.vue';
import FormField from '@/components/form/common/form/FormField.vue';
import FormCol from '@/components/form/common/form/layout/FormCol.vue';
import FormRow from '@/components/form/common/form/layout/FormRow.vue';
import FormContent from '@/components/form/common/form/layout/FormContent.vue';
import TextInputFormField from '@/components/form/common/form/fields/TextInputFormField.vue';
import {currencies} from "@/utils/currencies";

@Component(
  {
    components: {
      TextInputFormField,
      FormField,
      FormContent,
      FormRow,
      FormCol,
      Modal
    },
    directives: {}
  })
export default class P2PDestinationData extends DataFormBase implements IForm {
  constructor() {
    super();
  }

  copy(item: any) {
    window.navigator.clipboard.writeText(item);
  }

  get amountFormatted(): string {
    let symbol = currencies[this.formData.currency];
    let formatted = Intl.NumberFormat(this.$i18n.locale, {
      style: 'currency',
      currency: this.formData.currency == 'USDT' ? 'USD' : this.formData.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: this.formData.currencyScale != null ? this.formData.currencyScale : 2
    }).format(this.formData.p2pDestinationInfo.amount);

    if (symbol) {
      formatted = formatted.replace(this.formData.currency, symbol);
    }
    if (this.formData.currency == 'USDT') {
      formatted = formatted.replaceAll(/[^\d.-]/g, '') + ' USDT';
    }
    if (this.formData.currency == 'USDT') {
      formatted = formatted.replaceAll(/[^\d.,-]/g, '') + ' USDT';
    }
    return formatted;
  }

  get amountForCopy(): string {
    return Intl.NumberFormat(this.$i18n.locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: this.formData.currencyScale != null ? this.formData.currencyScale : 2
    }).format(this.formData.p2pDestinationInfo.amount).replace(',', '');
  }
}
</script>

<style lang="scss" scoped>
</style>
